import React from 'react'
import { Link } from 'gatsby'
import Logo from 'components/logo'
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTwitter } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Navi extends React.Component {
  render() {
    const { location, title } = this.props

    function maskEmail() {
      window.open('mailto:anita@relationart.com', '_self')
    }

    return (
      <nav className="navbar navbar-expand navbar-light flex-row flex-column bg-white">
        <div class="container">
          <Link className="navbar-brand d-flex align-items-center" to="/">
            <Logo></Logo>
            <div class="ml-3 title_font">{title}</div>
          </Link>
          {/* <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavDropdown-3"
            aria-controls="navbarNavDropdown-3"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button> */}
          <div
            // class="collapse navbar-collapse mr-auto text-center"
            id="navbarNavDropdown-3"
          >
            <ul class="navbar-nav ml-auto">
              <li class="nav-item">
                <a class="nav-link" href="https://twitter.com/relationart2">
                  <FontAwesomeIcon icon={faTwitter} />
                  <span className="noshow"> Twitter</span>
                </a>
              </li>

              {/* <li class="nav-item">
                        <a class="nav-link" href="https://facebook.com/relationsart">
                            <i class="fab fa-facebook-f"></i><span className="noshow">Facebook</span>
                        </a>
                    </li> */}

              <li class="nav-item">
                <a
                  class="nav-link"
                  href="https://instagram.com/relation_artistry"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                  <span className="noshow"> Instagram</span>
                </a>
              </li>

              <li class="nav-item">
                <a href="#" onClick={maskEmail} class="sendmail nav-link">
                  {/* <i class="far fa-envelope"></i>Email */}
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span className="noshow"> Email</span>
                </a>
              </li>
            </ul>
          </div>
          {/* <div className="navbar-nav-scroll">
            <ul className="navbar-nav bd-navbar-nav flex-row">
              <li
                className={
                  location.pathname === '/' ? 'nav-item active' : 'nav-item'
                }
              >
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </li>
              <li
                className={
                  location.pathname === '/profile/'
                    ? 'nav-item active'
                    : 'nav-item'
                }
              >
                <Link to="/profile/" className="nav-link">
                  Profile
                </Link>
              </li>
            </ul>
          </div>
          <div className="navbar-nav flex-row ml-md-auto d-none d-md-flex" /> */}
        </div>
      </nav>
    )
  }
}

export default Navi
