import { Link } from 'gatsby'
import React from 'react'
import './style.scss'

const Footer = ({ author, title }) => (
  <div className="footer">
    <div class="container">
      <div class="d-flex flex-column flex-md-row justify-content-between align-items-center">
        <div class="links">
          <ul class="footer-menu list-unstyled d-flex flex-row text-center text-md-left">
            <li>
              <Link to="/about/">About Us</Link>
            </li>{' '}
          </ul>
        </div>
        <div class="text-right">
          <p class="small">&copy; 2020 Relation Art</p>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
